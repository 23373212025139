import React, { useEffect } from "react"
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from "../components/Layout/Layout";
import { LargeHeader } from "../components/TextComponents/TextComponents";
import HtmlParser from '../components/HtmlParser/HtmlParser';
import Hero from '../components/Hero/Hero';
import Seo from "../components/Seo/Seo";
import HeroImage from './assets/Book Now_main image@2x.jpg';
import HeroImageBlur from './assets/Book Now_main image@2x_blur.jpg';
import HeroMobileImage from './assets/mobile/Book Now_main image@2x.jpg';
import HeroMobileImageBlur from './assets/mobile/Book Now_main image_blur@2x.jpg';
import { setNavColor, setNavDefaultColor, setCurrentPage } from "../actions";
import { fontstack } from '../utils/fontstack';
import { type } from '../utils/type';


const Wrapper = styled.div` 
  position: relative;
`

const Content = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 216px;
  color: white;
`
const Copy = styled.div` 
  ${fontstack.default}
  ${type('body')}
`
// markup
const BookPage = ({data}) => {
  const dispatch = useDispatch();
  const document = data.allPrismicBookNow.edges[0].node.data;

  useEffect(() => {
    dispatch(setCurrentPage('/book'));
  },[]);

  return (
    <Layout defaultColor='white'>
        <Seo title="Book"/>
        <Wrapper>
        <Hero desktop={{src: document.hero_image[0].image.url, placeholder: document.hero_image[0].image.url+'&w=100&blur=10&q=50'}} 
        mobile={{src: document.hero_image[1].image.url, placeholder: document.hero_image[1].image.url+'&w=100&blur=10&q=50'}}/>
          <Content>
            <LargeHeader style={{margin: '0 0 25px'}}>{document.title}</LargeHeader>
            <Copy><HtmlParser>{document.copy.html}</HtmlParser></Copy>
          </Content>
        </Wrapper>  
    </Layout>
  )
}

export const query = graphql`
query BookNowQuery {
  allPrismicBookNow {
    edges {
      node {
        data {
          hero_image {
            breakpoint
            image {
              url
              dimensions {
                height
                width
              }
              alt
            }
          }
          title
          copy {
            html
          }
        }
      }
    }
  }
}

`

export default BookPage